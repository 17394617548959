import React, { FormEvent, useCallback, useRef, useState } from "react";
import { navigate } from "gatsby";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Layout } from "~/templates/Layout";
import { VALIDATION_DEFAULT_MESSAGE, VALIDATION_DEFAULT_TEXT, VALIDATION_DEFAULT_VALUES } from "~/utils/validation";
import { colors } from "~/utils/colors";
import { SubmitButton } from "~/atoms/SubmitButton";
import rgba from "polished/lib/color/rgba";
import { getGaClientId } from "~/utils/getGaClientId";

type FormValues = {
  email: string;
  message: string;
};

const TITLE = "メール配信の停止";

const validationSchema = Yup.object().shape({
  email: Yup.string().required(VALIDATION_DEFAULT_MESSAGE.required).email(VALIDATION_DEFAULT_TEXT.email),
  message: Yup.string().max(VALIDATION_DEFAULT_VALUES.maxText, VALIDATION_DEFAULT_TEXT.maxText),
});

const initialValues = {
  email: "",
  message: "",
};

export const UnsubscribeForm: React.FC<{ formspreeEndpoint: string; thanksSlug: string }> = ({
  formspreeEndpoint,
  thanksSlug,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [result, setResult] = useState<{
    status: boolean;
    message: string;
  } | null>(null);

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => handleSubmit(values),
    validationSchema,
  });

  const handleServerResponse = (status, message) => {
    setSubmitting(false);
    setResult({
      status,
      message,
    });

    if (status) {
      formik.resetForm({ values: formik.values });
      formRef.current?.reset();
      navigate(`/unsubscribe/${thanksSlug}/thanks`);
    }
  };

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (!formspreeEndpoint) return null;
      setSubmitting(true);

      const { email, message } = values;
      const formData = new FormData();
      const gaCid = getGaClientId();
      formData.append(`email`, email);
      formData.append(`message`, message);
      formData.append(`gaClientId`, gaCid || "");

      try {
        await axios({
          method: "post",
          url: formspreeEndpoint,
          data: formData,
        });
        handleServerResponse(true, `success`);
        ga("set", "dimension2", gaCid);
      } catch (error) {
        handleServerResponse(false, error.response.data.error);
        throw new Error(error.message);
      }

      return null;
    },
    [submitting, result]
  );

  return (
    <>
      <style jsx>{`
        .wrapper {
          background-color: ${colors.white};
          padding: 0 30px;
          margin: 30px auto 50px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          max-width: 500px;
        }
        .title {
          font-size: 24px;
          margin: 0 0 20px;
        }
        .description {
          font-size: 14px;
          margin: 0 0 20px;
        }
        .form {
          position: relative;
        }
        .formGroup {
          margin-bottom: 20px;
        }
        .label {
          font-size: 13px;
        }
        .textInput {
          font-size: 16px;
          line-height: 1.5;
          border: 1px solid ${colors.gray3};
          background-color: ${colors.gray3};
          border-radius: 4px;
          padding: 6px 8px;
          height: 38px;
          width: 100%;
          box-sizing: border-box;
          display: block;
          box-shadow: none;
        }
        .textInput::placeholder {
          color: ${colors.gray2};
        }
        .textInput:focus {
          outline: 0;
          border: 1px solid ${colors.orange};
          box-shadow: 0 0 3px 3px ${rgba(colors.orange, 0.1)};
          background-color: ${colors.white};
          transition: all 0.15s ease-out;
        }
        .textarea {
          font-size: 16px;
          line-height: 1.5;
          border: 1px solid ${colors.gray3};
          background-color: ${colors.gray3};
          border-radius: 4px;
          padding: 6px 8px;
          width: 100%;
          box-sizing: border-box;
          display: block;
          box-shadow: none;
          height: ${15 * 1.5 * 7 + 12}px;
        }
        .textarea::placeholder {
          color: ${colors.gray2};
        }
        .textarea:focus {
          outline: 0;
          border: 1px solid ${colors.orange};
          box-shadow: 0 0 3px 3px ${rgba(colors.orange, 0.1)};
          background-color: ${colors.white};
          transition: all 0.15s ease-out;
        }
        .errorText {
          color: ${colors.red};
          display: block;
          font-size: 13px;
        }
        .action {
          display: flex;
          justify-content: center;
          margin: 30px 0 0;
        }
        .submitButton {
          background-position: calc(100% - 30px) center;
          background-size: 15px auto;
          background: linear-gradient(
            270deg,
            #ea3375 -73%,
            #ea3572 -69.4%,
            #eb4656 -31.72%,
            #ec5145 1.32%,
            #ec553f 25.96%,
            #ec8317 100%
          );
          border-radius: 50px;
          position: relative;
          display: block;
          text-align: center;
          padding: 15px 20px;
          box-shadow: 0 0 12px ${rgba(colors.black, 0.1)};
          color: ${colors.white};
          font-size: 15px;
          font-weight: bold;
          transition: all 0.15s ease-out;
          max-width: 500px;
          width: 100%;
          opacity: 1;
        }
        .submitButton:hover {
          opacity: 0.8;
        }
        .submitButton:disabled {
          background: none;
          background-color: ${colors.gray3};
          color: ${colors.gray2};
          cursor: default;
          box-shadow: none;
        }
        .submitButton:disabled:hover {
          opacity: 1;
        }
      `}</style>
      <Layout title={TITLE}>
        <div className="wrapper">
          <h1 className="title">{TITLE}</h1>
          <p className="description">
            メール配信の停止をご希望される場合は、以下のフォームを送信してください。なお、セミナー申込後の受講票などのお客様への重要なお知らせメールは、配信停止後も引続きお受け取りいただけます。
          </p>
          <div className="container">
            <form
              className="form"
              ref={formRef}
              onSubmit={(e: FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              <div className="formGroup">
                <label htmlFor="email" className="label">
                  メールアドレス
                </label>
                <input
                  className="textInput"
                  type="email"
                  name="email"
                  value={formik.values.email}
                  placeholder="メールアドレス"
                  onChange={(e: FormEvent<HTMLInputElement>) => {
                    e.preventDefault();
                    formik.setFieldValue("email", e.currentTarget.value, true);
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email && (
                  <span className="errorText">{formik.errors.email}</span>
                )}
              </div>
              <div className="formGroup">
                <label htmlFor="message" className="label">
                  今後のサービス向上のため、配信停止される理由をお聞かせください
                </label>
                <textarea
                  className="textarea"
                  id="message"
                  name="message"
                  value={formik.values.message}
                  rows={10}
                  onChange={(e: FormEvent<HTMLTextAreaElement>) => {
                    e.preventDefault();
                    formik.setFieldValue("message", e.currentTarget.value, true);
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.message && formik.errors.message && (
                  <span className="errorText">{formik.errors.message}</span>
                )}
              </div>
              <SubmitButton disabled={!formik.dirty || !formik.isValid || submitting} />
            </form>
          </div>
        </div>
      </Layout>
    </>
  );
};
