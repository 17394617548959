// @Note: 取引先責任者用のオプトアウトフォーム
// ref: https://formspree.io/forms/xoqpkjab/submissions

import React from "react";
import { UnsubscribeForm } from "~/organisms/UnsubscribeForm";

const ENDPOINT = "https://formspree.io/f/xoqpkjab";
const UnsubscribeContactPage: React.FC = () => <UnsubscribeForm formspreeEndpoint={ENDPOINT} thanksSlug="contact" />;

export default UnsubscribeContactPage;
